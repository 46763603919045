import React from "react";
import Grid from "@material-ui/core/Grid";

import blackLogo from '../assets/logo-black.svg';
import whiteLogo from '../assets/logo-white.svg';

interface Props {
  color?: 'white' | 'black';
}

const Logo = ({color = 'black'}: Props) => {
  const source = color === 'white' ? whiteLogo : blackLogo;

  return (
    <Grid container justify="center">
      <Grid item>
        <img src={source} alt="logo"/>
      </Grid>
    </Grid>
  );
}

export default Logo;
