import React from "react";
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

interface Props {
  children: React.ReactNode;
  onClick?: (event?: any) => void;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const StyledButton = withStyles(() => ({
  root: {
    color: '#fff',
    minWidth: 180,
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
}))(Button);

const ButtonComponent = ({children, onClick, disabled, type = 'button'}: Props) => {

  return (
    <StyledButton onClick={onClick} type={type} disabled={disabled} variant="contained" size="large">
      {children}
    </StyledButton>
  );
}

export default ButtonComponent;
