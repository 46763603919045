import {RouteProps} from "react-router-dom";

import ServiceQualityAssessment, {serviceQualityAssessmentPath} from './serviceQualityAssessmentRoute';
import ReasonForCancelingTheOrder from "../pages/reasonForCancelingTheOrder";
import EndQuestionnaire from "../pages/endQuestionnaire";


const home = {
  path: '/',
  component: undefined,
  exact: true,
} as RouteProps;

const serviceQualityAssessment = {
  path: serviceQualityAssessmentPath,
  component: ServiceQualityAssessment,
} as RouteProps;

const reasonForCancelingTheOrder = {
  path: '/reason-for-canceling-the-order',
  component: ReasonForCancelingTheOrder,
} as RouteProps;

const complete = {
  path: '/complete-questionnaire',
  component: EndQuestionnaire,
} as RouteProps;

export default [
  home,
  serviceQualityAssessment,
  reasonForCancelingTheOrder,
  complete,
] as RouteProps[];


export const getRouteKey = (route: RouteProps) => Array.isArray(route.path) ? route.path.join('.') : route.path;


