import React from 'react';
import {Route, Switch} from "react-router-dom";

import CallCenter from "../pages/callCenterServiceQualityAssessment";
import Complex from "../pages/complexServiceQualityAssessment";

export const serviceQualityAssessmentPath = '/service-quality-assessment';

const ServiceQualityAssessmentComponent = () => {

  return (
    <Switch>
      <Route path={`${serviceQualityAssessmentPath}/call-center`} component={CallCenter} />
      <Route path={`${serviceQualityAssessmentPath}/complex`} component={Complex} />
    </Switch>
  );
};

export default ServiceQualityAssessmentComponent;
