import React from "react";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import Box from "@material-ui/core/Box";

interface Props {
  errors: FieldErrors;
}

const ValidationError = ({errors}: Props) => {
  const entryError = Object.values(errors);

  if (entryError.length) {
    return (
      <>
        {
          entryError.map((error, index) => (
            <Box textAlign="center" color="red" key={`error-${index}`}>
                {error.message}
            </Box>
          ))
        }
      </>
    )
  }

  return null;
}

export default ValidationError;
