import React from "react";
import Box from "@material-ui/core/Box";

interface Props {
  text: string|null;
}

const ErrorMessage = ({text}: Props) => {

  if (text) {
    return (
      <Box textAlign="center" color="red">
        {text}
      </Box>
    )
  }

  return null;
}

export default ErrorMessage;
