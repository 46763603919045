import React from 'react';
import Container from "@material-ui/core/Container";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import routes, {getRouteKey} from './routes';

import theme from "./theme";

function App() {

  return (
    <ThemeProvider<any> theme={theme}>
      <Container>
        <BrowserRouter>
          <Switch>
            {routes.map((route) => (<Route {...route} key={getRouteKey(route)} />))}
          </Switch>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
