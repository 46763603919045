
export class ErrorMessageGetter {
  getMessage(data: any): string {
    let message = 'При відправленні форми сталася помилка';

    if (data?.response?.data) {
      message += this.getMessageFromError(data.response.data?.error?.message || data.response.data)
    }

    return message;
  }

  private getMessageFromError(data: any): string {
    let message = ': ';
    if (typeof data === 'string') {
      if (data.indexOf('It questionnaire already complete') !== -1) {
        message += 'Ви вже заповнили форму.';
      }
      else if (data.indexOf('User has not received the message yet') !== -1) {
        message += 'Ви ще не отримували повідомлення про форму.';
      }
      else if (data.indexOf('Entity not found') !== -1) {
        message += 'форми не існує.';
      }
    }

    return message === ': ' ? '' : message;
  }
}
