import React from "react";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import {Controller, useFormContext} from "react-hook-form";
import makeStyles from '@material-ui/core/styles/makeStyles';

import {StyledFormLabel, StyledRadio} from "../radio";

interface Props {
  name: string;
}

const useStyles = makeStyles({
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1.5rem',
    }
  }
});

const RateComponent = ({name}: Props) => {
  const styles = useStyles();
  const { control } = useFormContext();

  return (
    <Grid container justify="center">
      <Grid item>
        <Controller
          as={
            <RadioGroup row className={styles.label}>
              <StyledFormLabel
                labelPlacement="top"
                value="1"
                control={<StyledRadio />}
                label="1"
              />
              <StyledFormLabel
                labelPlacement="top"
                value="2"
                control={<StyledRadio />}
                label="2"
              />
              <StyledFormLabel
                labelPlacement="top"
                value="3"
                control={<StyledRadio />}
                label="3"
              />
              <StyledFormLabel
                labelPlacement="top"
                value="4"
                control={<StyledRadio />}
                label="4"
              />
              <StyledFormLabel
                labelPlacement="top"
                value="5"
                control={<StyledRadio />}
                label="5"
              />
            </RadioGroup>
          }
          name={name}
          control={control}
        />
        <Grid item container justify="space-between">
          <Typography style={{color: '#6659b6'}}>
            1-погано
          </Typography>
          <Typography style={{color: '#6659b6'}}>
            5-добре
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RateComponent;
