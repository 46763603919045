import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {useHistory} from "react-router-dom";

import yup from '../service/yup';
import Button from "../components/button";
import Logo from "../components/logo";
import RateComponent from "../components/rate";
import TextForm from "../components/textForm";
import ValidationError from "../components/validationError";
import ErrorMessage from "../components/errorMessage";
import {useQuery} from "../hooks/router";
import {QuestionnaireStatus} from "../infrastructure/constants";
import FluentService from '../service/api/fluentService';
import FormResultService from '../service/api/formService';
import { ErrorMessageGetter } from "../service/error";

interface FormData {
  description: string;
  rate: string;
}

const defaultValues = {
  description: '',
  rate: '0',
};

const schema = yup.object().shape({
  description: yup.string(),
  rate: yup.string().required().notOneOf(['0'], 'Оцінка обов\'язкове поле для заповнення'),
});

const errorMessageGetter = new ErrorMessageGetter();

const CallCenterServiceQualityAssessment = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string|null>(null);
  const query = useQuery();
  const history = useHistory();
  const questionnaireId = query.get('id');

  const formMethods = useForm<FormData>({defaultValues, resolver: yupResolver(schema)});

  React.useEffect(
    () => {
      if (questionnaireId) {
        FormResultService.getFormStatus(questionnaireId)
          .then((status) => {
            if (status !== 'SENT_MESSAGE_SUCCESS') {
              history.push('/complete-questionnaire?resubmitted=1');
            }
          })
          .catch((e) => {
            setError(errorMessageGetter.getMessage(e));
          });
        FluentService.sendLog({
          questionnaireId: questionnaireId,
          status: QuestionnaireStatus.VISIT_FORM,
        });
      }
    },
    []
  );

  const onSubmit = React.useCallback(
    async (data: FormData) => {
      if (!questionnaireId) {
        return;
      }
      try {
        setError(null);
        setLoading(true);
        const result = {
          ...data,
          rate: Number(data.rate),
        }
        await FormResultService.sendFormResult({
          questionnaireId: questionnaireId,
          result: result,
        });
        history.push('/complete-questionnaire');
      } catch (e) {
        setError(errorMessageGetter.getMessage(e));
      }
      setLoading(false);
    },
    []
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{margin: 20}}>
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Чи сподобався Вам наш виріб?
            </Typography>
            <Box bgcolor="#f6f6f6">
              <RateComponent name="rate" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Уточніть, будь ласка, причину, по якій Ви поставили таку оцінку.
            </Typography>
            <Grid container>
              <TextForm
                placeholder="Ваш текст"
                name="description"
                rows={5}
                multiline
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ValidationError errors={formMethods.errors} />
            <ErrorMessage text={error} />
          </Grid>
          <Grid item container justify="center">
            <Button type="submit" disabled={isLoading}>
              Оцінити
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default CallCenterServiceQualityAssessment;
