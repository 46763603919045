import React from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import {OutlinedTextFieldProps} from "@material-ui/core/TextField/TextField";
import {Controller, useFormContext} from "react-hook-form";

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#d8d8d8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d8d8d8',
      },
      '&:hover fieldset': {
        borderColor: '#d8d8d8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d8d8d8',
      },
    },
  }
})(React.forwardRef((props: OutlinedTextFieldProps, ref: any) => <TextField {...props} ref={ref} />));

type Props =  Omit<OutlinedTextFieldProps, 'variant' | 'onChange'> & {
  name: string;
}

const TextForm = (props: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      as={(
        <StyledTextField
          fullWidth
          variant="outlined"
          {...props}
        />
      )}
      name={props.name}
      control={control}
    />
  );
}

export default TextForm;
