import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme(
  {
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      primary: {
        light: '#fff',
        main: '#47b2e4',
        dark: '#000',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#000',
        main: '#000',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#26325f',
        secondary: '#37517e',
        disabled: '',
        hint: '',
      },
    },
  },
);

export default theme;
