import axios from "axios";
import {API_URL, QuestionnaireStatus} from "../../infrastructure/constants";

class FluentService {
  private url = `${API_URL}/api/fluentlog/questionnaire`;


  async sendLog(payload: SendLogPayload): Promise<void> {
    try {
      await axios.post(this.url, payload);
    } catch (e) {
      console.error(e);
    }
  }

}

interface SendLogPayload {
  questionnaireId: string;
  status: QuestionnaireStatus;
  additionalData?: any;
}

export default new FluentService;
