import printValue from "yup/es/util/printValue";
/* eslint-disable no-template-curly-in-string, no-useless-concat */
export const mixed = {
  default: '${path} недійсний',
  required: '${path} є обов’язковим полем',
  oneOf: '${path} має бути одне з наступних значень: ${values}',
  notOneOf: '${path} не повинно бути одним із наступних значень: ${values}',
  notType: ({
              path,
              type,
              value,
              originalValue
            }: any) => {
    const isCast = originalValue != null && originalValue !== value;
    let msg = `${path} повинен бути \`${type}\` тип, ` + `але остаточне значення було: \`${printValue(value, true)}\`` + (isCast ? ` (відкинути від значення \`${printValue(originalValue, true)}\`).` : '.');

    if (value === null) {
      msg += `\n Якщо "null" призначено як порожнє значення, обов’язково позначте схему як \`.nullable()\``;
    }

    return msg;
  },
  defined: '${path} повинні бути визначені'
};
export const string = {
  length: '${path} має бути точно ${length} символів',
  min: '${path} має бути не менше ${min} символів',
  max: '${path} повинно бути не більше ${max} символів',
  matches: '${path} повинні відповідати наступним: "${regex}"',
  email: '${path} має бути дійсним email',
  url: '${path} має бути дійсним URL',
  uuid: '${path} має бути дійсним UUID',
  trim: '${path} повинен бути текстом без пробілів',
  lowercase: '${path} має бути текстом у нижньому регістрі',
  uppercase: '${path} має бути текстом верхнього регістру'
};
export const number = {
  min: '${path} має бути більшим або рівним ${min}',
  max: '${path} має бути меншим або рівним ${max}',
  lessThan: '${path} має бути менше ${less}',
  moreThan: '${path} має бути більше ${more}',
  positive: '${path} має бути додатним числом',
  negative: '${path} має бути від’ємним числом',
  integer: '${path} має бути цілим числом'
};
export const date = {
  min: '${path} поле має бути не менше ніж ${min}',
  max: '${path} Поле має бути не більше, ніж ${max}'
};
export const boolean = {
  isValue: '${path} поле повинно бути ${value}'
};
export const object = {
  noUnknown: '${path} поле має невизначені ключі: ${unknown}'
};
export const array = {
  min: '${path} поле має мати принаймні ${min} пункти',
  max: '${path} поле повинно мати менше або дорівнює ${max} пункти',
  length: '${path} повинно бути ${length} пункти'
};
export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean
});
