import axios from "axios";
import {API_URL, QuestionnaireStatus} from "../../infrastructure/constants";
import FluentService from "./fluentService";

class FormService {
  async sendFormResult(payload: SendResultPayload): Promise<any> {
    try {
      const result = await axios.post(`${API_URL}/api/form-result`, payload);
      FluentService.sendLog({
        questionnaireId: payload.questionnaireId,
        status: QuestionnaireStatus.SENT_FORM_SUCCESSFULLY,
        additionalData: payload.result,
      });
      return result.data;
    } catch (e) {
      FluentService.sendLog({
        questionnaireId: payload.questionnaireId,
        status: QuestionnaireStatus.SENT_FORM_FAILED,
        additionalData: {
          response: e?.response?.data || undefined,
          message: e.message,
        },
      });
      console.error(e);
      throw e;
    }
  }

  async getFormStatus(questionnaireId: string): Promise<string> {
    try {
      const result = await axios.get(`${API_URL}/api/form-status/${questionnaireId}`);

      return result?.data?.status || '';
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

interface SendResultPayload {
  questionnaireId: string;
  result: object;
}

export default new FormService;
