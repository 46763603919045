import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import pack from "../assets/pack.png";
import Logo from "../components/logo";
import Button from "../components/button";
import {useQuery} from "../hooks/router";

const EndQuestionnaire = () => {
  const query = useQuery();
  const isResubmitted = query.get('resubmitted');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{margin: 20}}>
        <Logo />
      </Grid>
      {isResubmitted ? (
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Ви вже заповнювали форму, дякуємо, що нас оцінили!
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Дякуємо, що нас оцінили!
          </Typography>
        </Grid>
        )
      }
      <Grid item xs={12}>
        <Typography align="center" style={{fontSize: '1.2rem'}}>
          Завдяки Вашому відгуку, нам буде легше покращити якість обслуговування.
        </Typography>
      </Grid>
      <Grid item container justify="center">
        <img src={pack} alt="pack" />
      </Grid>
      <Grid item container justify="center">
        <a href="https://dressa.com.ua" style={{textDecoration: 'unset'}}>
          <Button type="submit">
            Перейти на сайт
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}

export default EndQuestionnaire;
