import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Radio, {RadioProps} from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const StyledRadio = withStyles({
  root: {
    color: '#8e8e8e',
    '&$checked': {
      color: '#6659b6',
    },
    '& svg': {
      fontSize: '2rem',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const StyledFormLabel = withStyles(({breakpoints}) => ({
  root: {
    [breakpoints.down(415)]: {
      margin: '0 6px 0 6px',
    },
    [breakpoints.down(321)]: {
      margin: '0',
    },
  },
  label: {
    fontSize: '1rem',
  },
}))(React.forwardRef((props: any, ref) => (<FormControlLabel {...props} ref={ref} />)));

