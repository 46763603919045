import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';

import yup from '../service/yup';
import {StyledFormLabel, StyledRadio} from "../components/radio";
import Logo from "../components/logo";
import TextForm from "../components/textForm";
import Button from "../components/button";
import ValidationError from "../components/validationError";
import {useQuery} from "../hooks/router";
import {useHistory} from "react-router-dom";
import {QuestionnaireStatus} from "../infrastructure/constants";
import FluentService from "../service/api/fluentService";
import FormResultService from '../service/api/formService';
import ErrorMessage from "../components/errorMessage";
import {ErrorMessageGetter} from "../service/error";

interface FormData {
  answer: string;
  description: string;
}

const defaultValues = {
  answer: '0',
  description: '',
};

const schema = yup.object().shape({
  answer: yup.string().required().notOneOf(['0'], 'Причина обов\'язкове поле для заповнення'),
  description: yup.string(),
});

const errorMessageGetter = new ErrorMessageGetter();

const ReasonForCancelingTheOrder = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string|null>(null);
  const query = useQuery();
  const history = useHistory();
  const questionnaireId = query.get('id');

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  React.useEffect(
    () => {
      if (questionnaireId) {
        FormResultService.getFormStatus(questionnaireId)
          .then((status) => {
            if (status !== 'SENT_MESSAGE_SUCCESS') {
              history.push('/complete-questionnaire?resubmitted=1');
            }
          })
          .catch((e) => {
            setError(errorMessageGetter.getMessage(e));
          });
        FluentService.sendLog({
          questionnaireId: questionnaireId,
          status: QuestionnaireStatus.VISIT_FORM,
        });
      }
    },
    []
  );

  const onSubmit = React.useCallback(
    async (data: FormData) => {
      if (!questionnaireId) {
        return;
      }

      setLoading(true);
      try {
        setError(null);
        const result = {
          ...data,
          answer: Number(data.answer),
        }
        await FormResultService.sendFormResult({
          questionnaireId: questionnaireId,
          result: result,
        });
        history.push('/complete-questionnaire');
      } catch (e) {
        setError(errorMessageGetter.getMessage(e));
      }
      setLoading(false);
    },
    [setLoading, questionnaireId, history]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{margin: 20}}>
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Виберіть причину, по якій Ви відмовилися від замовлення.
            </Typography>
            <Grid item container>
              <Controller
                as={
                  <RadioGroup>
                    <StyledFormLabel
                      value="1"
                      control={<StyledRadio/>}
                      label="Не підійшов розмір"
                    />
                    <StyledFormLabel
                      value="2"
                      control={<StyledRadio/>}
                      label="Замовлення має пошкодження"
                    />
                    <StyledFormLabel
                      value="3"
                      control={<StyledRadio/>}
                      label="Інший варіант"
                    />
                  </RadioGroup>
                }
                name="answer"
                control={formMethods.control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextForm
              placeholder="Ваш текст"
              name="description"
              rows={5}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <ValidationError errors={formMethods.errors} />
            <ErrorMessage text={error} />
          </Grid>
          <Grid item container justify="center">
            <Button type="submit" disabled={isLoading}>
              Надіслати
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default ReasonForCancelingTheOrder;
